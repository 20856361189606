angular
  .module('app')
  .service('orderService', orderService);

/** @ngInject */
function orderService($rootScope, $log, $q, $window, toaster, orderFactory) {
  var self = {
    // OBJECTS
    orderData: {
      additionalInfo: '',
      address: {
        street: ''
      },
      attendant: {
        email: '',
        name: ''
      },
      customer: {},
      origin: 'phone',
      voucherUber: ''
    },

    // STRINGS
    batteryBrand: 'moura',
    resume: '',

    // FLAGS
    isPlacingOrder: null,
    triedToOrder: null,

    // METHODS
    getEmailName: function (email) {
      var name = email.split('@')[0];
      name = name.replace(/\.(\w)/g, function (_, initial) {
        return ' ' + initial.toUpperCase();
      });
      return name.charAt(0).toUpperCase() + name.slice(1);
    },

    placeOrder: function () {
      if (self.isPlacingOrder) {
        return;
      }

      self.isPlacingOrder = true;

      var defer = $q.defer();

      var attendantEmail = localStorage.getItem('currentUserEmail');

      self.orderData.attendant.email = $rootScope.attendantEmail || attendantEmail;
      self.orderData.attendant.name = $rootScope.attendantName || this.getEmailName(attendantEmail);

      if (self.batteryBrand === 'moura') {
        delete self.orderData.battery.alt;
      } else {
        self.orderData.battery = self.orderData.battery.alt;
      }

      if (self.orderData.assistenciaFacilPoc) {
        self.orderData.additionalInfo += 'Ação: Assistência Fácil\n';
      }

      if (!self.triedToOrder) {
        self.orderData.additionalInfo += 'Garantia: ' + self.orderData.battery.warranty + ' meses\n';

        if (self.orderData.leadSource === 'mapfre') {
          self.orderData.additionalInfo += 'Ação: MAPFRE\n';
        }
      }

      // self.orderData.address.country = 'BR';

      if (!self.orderData.address.number) {
        self.orderData.address.number = 'S/N';
      }

      if (self.orderData.paymentType === 'Cash' || self.orderData.paymentType === 'Pix') {
        self.orderData.creditCardBrand = null;
      }

      if (self.orderData.paymentType !== 'CreditCard') {
        self.orderData.installments = null;
      }

      if (self.orderData.assistenciaFacilPoc) {
        self.orderData.partner = 'assistenciafacil';
      }

      if (self.orderData.origin === 'mercadolivre') {
        self.orderData.partner = 'mercado_livre';
      }

      if (self.orderData.origin === 'origin_mercadolivrepremium') {
        self.orderData.partner = 'mercadolivrepremium';
      }

      if (self.orderData.leadSource === 'mouramais') {
        self.orderData.partner = 'mouramais';
      }

      if (self.orderData.battery.model.startsWith('Z')) {
        self.orderData.partner = 'zettabateria';
      }

      if (self.orderData.scheduled) {
        var sanitizedScheduleTime;
        var today = moment();
        var tomorrow = moment().add(1, 'd');

        if (self.orderData.city.zone && self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('America/Recife');
        } else if (self.orderData.city.zone && !self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('America/Manaus');
        } else if (!self.orderData.city.zone && self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('Atlantic/South_Georgia');
        } else if (!self.orderData.city.zone && !self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('America/Recife');
        }

        self.orderData.scheduleTime.hour(self.orderData.selectedScheduleTime.hour);
        self.orderData.scheduleTime.minute(self.orderData.selectedScheduleTime.minute);
        self.orderData.scheduleTime.second(0);

        if (typeof self.orderData.scheduleTime === 'string') {
          sanitizedScheduleTime = self.orderData.scheduleTime;
        } else if (typeof self.orderData.scheduleTime === 'object') {
          sanitizedScheduleTime = self.orderData.scheduleTime.toISOString();
        }

        self.orderData.scheduleTime = sanitizedScheduleTime.replace(/\.[0-9]+Z/, '-0000');

        if (
          moment(self.orderData.scheduleTime).isAfter(today, 'd') &&
          !moment(self.orderData.scheduleTime).isAfter(tomorrow, 'd')
        ) {
          self.orderData.scheduledDays = '1';
        } else if (moment(self.orderData.scheduleTime).isAfter(tomorrow, 'd')) {
          self.orderData.scheduledDays = '2';
        }
      } else {
        self.orderData.scheduleTime = null;
      }

      self.orderData.ssnOnReceipt = true;

      if (self.orderData.leadSource === 'flutuantedigital') {
        self.orderData.utmTesteAB = 'flutuante_dig';
      } else if (self.orderData.leadSource === 'flutuantetel') {
        self.orderData.utmTesteAB = 'flutuante_tel';
      }

      self.orderData.vehicleType = 'Car';

      var timeBeforeTheRequet = moment();

      orderFactory
        .save(self.orderData)
        .$promise
        .then(function (response) {
          self.orderData.assistenciaFacilPoc = response.assistenciaFacilPoc;

          if (response.voucherUber) {
            self.resume += 'Voucher Uber: ' + response.voucherUber + '\n\n';
          }

          if (response.alert) {
            self.resume += 'Alerta: ' + response.alert + '\n\n';
          }

          if (response.scheduleTime) {
            const options = {
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            };

            var orderDate = new Date(self.orderData.scheduleTime);

            if (self.orderData.city.zone && !self.orderData.city.dst) {
              orderDate.setHours(orderDate.getHours() - 1);
            } else if (!self.orderData.city.zone && self.orderData.city.dst) {
              orderDate.setHours(orderDate.getHours() + 1);
            }

            self.resume += 'Entrega: Agendada\n';

            self.resume += 'Dia: ' + orderDate.toLocaleString('pt-BR', options) + '\n\n';
          } else {
            self.resume += 'Entrega: Imediata\n\n';
          }

          self.resume += 'Pedido: ' + response.shortId + '\n';

          self.resume += 'Carro: ' + response.vehicle + '\n';

          self.resume += 'Bateria: ' + response.battery.model + '\n';

          if (response.extraBattery) {
            self.resume += 'Bateria extra: ' + response.extraBattery + '\n';
          }

          if (
            (response.battery.cashPrice && response.paymentType !== 'CreditCard')
          ) {
            if (response.battery.cashDiscountPaymentType) {
              self.resume += 'Preço no ' + response.battery.cashDiscountPaymentType.toLowerCase() + ': R$ ' + response.battery.cashPrice + '\n';
            } else {
              self.resume += 'Preço à vista: R$ ' + response.finalPrice + '\n';
            }
          }

          if (response.paymentType === 'CreditCard') {
            if (response.battery.discount === 0) {
              self.resume += 'Preço no crédito: R$ ' + response.battery.price + '\n';
            } else {
              self.resume += 'Preço no crédito promocional: R$ ' + response.battery.discount + '\n';
            }

            self.resume += 'Forma de pagamento: Cartão de crédito\n';

            self.resume += 'Bandeira: ' + response.creditCardBrand + '\n';

            self.resume += 'Parcelas: ' + response.installments + '\n\n';
          }

          if (response.paymentType === 'DebitCard') {
            self.resume += 'Forma de pagamento: Cartão de débito\n';

            self.resume += 'Bandeira: ' + response.creditCardBrand + '\n\n';
          }

          if (response.paymentType === 'Cash') {
            self.resume += 'Forma de pagamento: Dinheiro\n';

            self.resume += 'Troco para: ' + response.change + '\n\n';
          }

          if (response.paymentType === 'Pix') {
            self.resume += 'Forma de pagamento: Pix\n';
          }

          self.resume += 'Endereço: ' + response.address.street + ', ' + response.address.number + '\n';

          self.resume += 'Bairro: ' + response.address.neighborhood + '\n';

          self.resume += 'Cidade: ' + response.address.city + ', ' + response.address.state + '\n';

          self.resume += 'CEP: ' + response.address.zipCode + '\n';

          if (response.address.complement) {
            self.resume += 'Complemento: ' + response.address.complement + '\n\n';
          } else {
            self.resume += '\n';
          }

          self.resume += 'Nome: ' + response.customer.name + '\n';

          self.resume += 'Telefone: ' + response.customer.phone;

          if (response.customer.email) {
            self.resume += '\nEmail: ' + response.customer.email;
          }

          if (response.customer.ssn) {
            var label = 'CPF';

            if (response.customer.ssn.length > 11) {
              label = 'CNPJ';
            }

            self.resume += '\n' + label + ': ' + response.customer.ssn;
          }

          if (self.orderData.additionalInfo) {
            self.resume += '\n\nInformações adicionais: ' + self.orderData.additionalInfo;
          }

          if (self.orderData.partner === 'zettabateria') {
            self.resume += '\n\nParceiro: Zetta';
          }

          var hasEmail = false;
          var hasScheduleTime = false;
          var hasSSN = false;
          var orderCoordinates = response.address.latitude.toString() + ',' + response.address.longitude.toString();
          var orderExtraBattery = false;
          var orderPartner = false;
          var orderTotal = 0;

          if (response.customer.email) {
            hasEmail = true;
          }

          if (response.scheduleTime) {
            hasScheduleTime = true;
          }

          if (response.customer.ssn) {
            hasSSN = true;
          }

          if (response.extraBattery !== '') {
            orderExtraBattery = response.extraBattery;

            dataLayer.push({
              event: 'Extra battery'
            });
          }

          if (response.partner) {
            orderPartner = response.partner;
          }

          if (response.battery.discount > 0) {
            orderTotal = response.battery.discount;
          } else {
            orderTotal = response.battery.price;
          }

          if (response.battery.model.startsWith('Z')) {
            dataLayer.push({
              event: 'Alternative battery'
            });
          }

          if (response.car.complex) {
            dataLayer.push({
              event: 'Complex installation'
            });
          }

          dataLayer.push({
            event: 'transacaoTelefone',
            transactionAdWordsCity: false,
            transactionAddressCity: response.address.city,
            transactionAddressCityCoordinates: orderCoordinates,
            transactionAddressNeighborhood: response.address.neighborhood,
            transactionAddressState: response.address.state,
            transactionAddressStreet: response.address.street + ', ' + response.address.number,
            transactionAddressZipCode: response.address.zipCode,
            transactionAffiliation: 'Plataforma de Atendimento',
            transactionCustomerEmail: hasEmail,
            transactionCustomerName: response.customer.name,
            transactionCustomerPhone: response.customer.phone,
            transactionCustomerSSN: hasSSN,
            transactionId: response.shortId,
            transactionOrigin: response.origin,
            transactionPartner: orderPartner,
            transactionProducts: {
              category: 'Baterias Automotivas',
              charge: response.charge,
              installments: response.installments,
              name: response.battery.model,
              price: response.battery.price,
              quantity: 1,
              sku: response.battery.model
            },
            transactionProductsDiscount: response.battery.discount,
            transactionProductsExtraBattery: orderExtraBattery,
            transactionProductsName: response.battery.model,
            transactionProductsPaymentType: response.paymentType,
            transactionScheduled: hasScheduleTime,
            transactionShipping: 0,
            transactionTax: 0,
            transactionTotal: orderTotal,
            transactionVehicle: response.vehicle
          });

          self.isPlacingOrder = false;
          self.triedToOrder = false;

          defer.resolve();
        }, function (reason) {
          var duration = moment.duration(moment().diff(timeBeforeTheRequet));
          var minutes = duration.asMinutes();

          $log.error('orderService.placeOrder', reason);

          $window.Raven
            .setUserContext({
              email: localStorage.getItem('currentUserEmail')
            })
            .setExtraContext(Object.assign(
              {
                timeOutErroMinutes: minutes
              },
              self.orderData
            ))
            .captureException(reason, {
              level: 'error',
              logger: 'orderService.placeOrder',
              sampleRate: 1
            });

          toaster.pop('error', 'Ops! Tente novamente.');

          self.isPlacingOrder = false;
          self.triedToOrder = true;
        });

      return defer.promise;
    },
    resetOrder: function () {
      var origin;

      if (
        localStorage.getItem('currentUserEmail') === 'joel.cipriano@tds.company' ||
        localStorage.getItem('currentUserEmail') === 'mourafacil1@speedmais.com.br' ||  // Nívea
        localStorage.getItem('currentUserEmail') === 'mourafacil19@speedmais.com.br' || // Elias
        localStorage.getItem('currentUserEmail') === 'mourafacil22@speedmais.com.br' || // Gilberto
        localStorage.getItem('currentUserEmail') === 'mourafacil37@speedmais.com.br'    // Poliana
      ) {
        origin = 'whatsapphumano';
      } else {
        origin = 'phone';
      }

      self.orderData = {};

      self.orderData = {
        additionalInfo: '',
        attendant: {
          email: $rootScope.attendantEmail,
          name: $rootScope.attendantName
        },
        scheduled: true,
        paymentType: 'CreditCard',
        creditCardBrand: 'MasterCard',
        installments: 3,
        customer: {
          origin: '',
          ssn: null
        },
        origin: origin,
        partner: null,
        voucherUber: ''
      };

      self.batteryBrand = 'moura';
    }
  };

  return self;
}
